import * as yup from "yup";
const validations = yup.object().shape({
  name: yup.string().required("validationMessages.required"),
  price: yup.string().required("validationMessages.required"),
  category: yup.string().required("validationMessages.required"),
  main_category: yup.string().required("validationMessages.required"),
  brand: yup.string().required("validationMessages.required"),
  unit: yup.string().required("validationMessages.required"),
  weight: yup.string().required("validationMessages.required"),
  barcode: yup.string().required("validationMessages.required"),
  card_type: yup.string().required("validationMessages.required"),
  slot_height: yup.string().required("validationMessages.required"),
  slot_width: yup.string().required("validationMessages.required"),
});

export default validations;
