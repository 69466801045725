import { axiosInstance } from ".";

export const userService = (state) => {
  return {
    async list(pageNo, pageSize, search) {
      const response = await axiosInstance.get(
        `private-user?page=${pageNo}&pageSize=${pageSize}${
          search ? `&search_key_word=${search}` : ""
        }`
      );
      return response?.data || [];
    },
    async deliveryUsers() {
      const response = await axiosInstance.get(`private-user/delivery-users`);
      return response?.data || [];
    },
    async publicUserList(pageNo, pageSize, search) {
      const response = await axiosInstance.get(
        `user/list?page=${pageNo}&pageSize=${pageSize}${
          search ? `&search_key_word=${search}` : ""
        }`
      );
      return response?.data || [];
    },
    async get(id) {
      const response = await axiosInstance.get(`private-user/${id}`);
      return response?.data || [];
    },
    async create(payload) {
      const response = await axiosInstance.post("private-user", payload, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });

      return response?.data || [];
    },
    async update(id, payload) {
      const response = await axiosInstance.put(`private-user/${id}`, payload, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });

      return response?.data || [];
    },

    async delete(id) {
      const response = await axiosInstance.get(`private-user/delete/${id}`);
      return response?.data || [];
    },
    async getActionLogs(pageNo, pageSize, data) {
      const response = await axiosInstance.post(
        `action-log?page=${pageNo}&pageSize=${pageSize}`,
        data
      );
      return response?.data || [];
    },

    async getDeviceActionLogs(pageNo, pageSize, key) {
      const response = await axiosInstance.get(
        `device-log?page=${pageNo}&pageSize=${pageSize}&key=${key}`
      );
      return response?.data || [];
    },

    async getTransactions(pageNo, pageSize, data) {
      const response = await axiosInstance.get(
        `transaction/list?page=${pageNo}&pageSize=${pageSize}`,
        data
      );
      return response?.data || [];
    },
    async getTransactionDetail(id) {
      const response = await axiosInstance.get(`transaction/details/${id}`);
      return response?.data || [];
    },
  };
};
