import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import services from "../../services";

import toast from "react-hot-toast";

import { useSearchParams } from "react-router-dom";
import { Pagination } from "@mantine/core";
import { useLayoutEffect } from "react";
import { useState } from "react";
import { useEffect } from "react";
import TableSkeleton from "../../components/Skeleton/TableSkeleton";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import dayjs from "dayjs";
import { dateCombineDateObject } from "../../utils/helper";

const RegisteredUserList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentPage, setCurrentPage] = useState(
    Number(searchParams.get("page")) ? Number(searchParams.get("page")) : 1
  );
  const [search, setSearch] = useState(
    searchParams.get("search") ? searchParams.get("search") : ""
  );
  const [pageSize, setPageSize] = useState(
    searchParams.get("pageSize") ? searchParams.get("pageSize") : 10
  );
  const [id, setId] = useState(null);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);

  const {
    isLoading,
    error,
    data,
    isFetching,
    refetch: refetchUsers,
  } = useQuery({
    queryKey: ["registeredUsers", currentPage, pageSize, search],
    enabled: !!search || (!!pageSize && !!currentPage),
    queryFn: async () => {
      return await services
        .getPublicUserList(currentPage, pageSize, search)
        .then((res) => res.data);
    },
  });
  useLayoutEffect(() => {
    const search = searchParams.get("search");
    if (search) {
      setSearchParams({ page: 1, pageSize: pageSize, search: search });
    } else {
      // setSearchParams({ page: , pageSize: pageSize });
    }
  }, [currentPage, pageSize, setSearchParams, search, searchParams]);
  useEffect(() => {
    if (data?.data?.length === 0 && currentPage > 1) {
      setSearchParams({ page: 1, pageSize: pageSize });
    }
  }, [data, currentPage, pageSize, setSearchParams]);
  if (error) return <p>Error :(</p>;

  const handleNavigate = (page, id) => {
    switch (page) {
      case "create":
        navigate("/user/create");
        break;
      case "edit":
        navigate("/user/edit/" + id);
        break;
      case "delete":
        setId(id);
        setDeleteModalVisible(true);

        break;
      default:
        navigate("/registered-user-list/list");
    }
  };
  const handlePageChange = (page) => {
    const search = searchParams.get("search");
    if (data?.data?.length === 0 && page > 1) {
      setCurrentPage(page - 1);
    }

    setCurrentPage(page);
    if (search) {
      setSearchParams({ page: page, pageSize: pageSize, search: search });
      return;
    } else {
      setSearchParams({ page: page, pageSize: pageSize });
      return;
    }
  };

  const handleDelete = async (id) => {
    await services.deleteUser(id).then((res) => {
      if (res.success) {
        toast.success(
          res?.message ? res.message : t("notifications.success.user-delete")
        );

        setDeleteModalVisible(false);
        refetchUsers();
      } else {
        toast.error(
          res?.message ? res.message : t("notifications.error.message")
        );
      }
    });
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6  dark:text-white text-slate-700">
            {t("titles.registered-user-list")}
          </h1>
          <p className="mt-2 text-sm dark:text-gray-400 text-gray-500">
            {t("descriptions.registered-user-list")}
          </p>
        </div>
      </div>
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="dark:bg-gray-800 bg-gray-300 text-slate-700">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold dark:text-gray-300 text-slate-700 sm:pl-6"
                    >
                      {t("user.name")}
                    </th>

                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold dark:text-gray-300 text-slate-700"
                    >
                      {t("user.email")}
                    </th>

                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold dark:text-gray-300 text-slate-700"
                    >
                      {t("user.phone-number")}
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold dark:text-gray-300 text-slate-700"
                    >
                      {t("user.birth-date")}
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold dark:text-gray-300 text-slate-700"
                    >
                      {t("user.registered-card-count")}
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold dark:text-gray-300 text-slate-700"
                    >
                      {t("user.purchase-product-count")}
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold dark:text-gray-300 text-slate-700"
                    >
                      {t("user.registered-date")}
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 dark:bg-gray-800 bg-gray-200 text-slate-700">
                  {isLoading || isFetching ? (
                    <TableSkeleton colSize={7} />
                  ) : (
                    data?.data?.map((person, index) => (
                      <tr
                        key={person.email}
                        className={`${
                          index % 2 === 0
                            ? "dark:bg-slate-700/80 bg-gray-200"
                            : "dark:bg-slate-600/80 bg-gray-100"
                        } hover:bg-orange-200/90 dark:hover:bg-orange-600/20`}
                      >
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium dark:text-gray-300 text-slate-700 sm:pl-6">
                          {person.name} {person.last_name}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {person.email}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          <a
                            href={
                              "tel:" +
                              person.phone_country_code +
                              person.phone_number
                            }
                            class="Blondie"
                          >
                            {person.phone_country_code}
                            {person.phone_number}
                          </a>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {dayjs(
                            dateCombineDateObject(
                              person.birth_day,
                              person.birth_month,
                              person.birth_year
                            )
                          ).format("DD/MM/YYYY")}
                        </td>{" "}
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {person.registered_card_count}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {person.purchased_product_count}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {dayjs(person.created_at).format(
                            "DD/MM/YYYY HH:mm:ss"
                          )}
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-5  overflow-x-auto w-full  sm:gap-1  sm:flex-row items-center  my-5">
          <div className="flex justify-center w-full items-center">
            <Pagination
              total={data?.totalPageCount}
              siblings={1}
              size={"md"}
              defaultValue={
                Number(searchParams.get("page"))
                  ? Number(searchParams.get("page"))
                  : 1
              }
              value={currentPage}
              onChange={handlePageChange}
              color="orange"
              classNames={{
                control:
                  "flex items-center justify-center gap-x-2 dark:disabled:!bg-gray-700/50  dark:[&[data-active=true]]:!bg-orange-700/50 dark:[&[data-active=true]]:!border-orange-700  dark:[&[data-active=true]]:!border-orange-700 [&[data-active=true]]:!bg-orange-600 dark:bg-orange-800/90 [&[data-active=true]]:!border-orange-300/90 !bg-gray-200/90 !border   dark:!bg-slate-800 dark:!text-white  :disabled:!bg-gray-100/50 !rounded-lg ",
              }}
            />
          </div>
          <div className=" flex justify-end w-[80px]">
            <CustomSelect
              withoutController={true}
              placeholder={t("placeholders.select")}
              onChange={(value) => {
                setPageSize(value);
                setSearchParams({ page: 1, pageSize: value || 10 });
                setCurrentPage(1);
              }}
              data={[5, 10, 20, 30, 50]}
              value={pageSize}
              name="pageSize"
              searchable={false}
              height={"20px"}
              width={20}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisteredUserList;
