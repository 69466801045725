import { useMutation, useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import services from "../../services";
import { useForm, useWatch } from "react-hook-form";
import { useCallback, useEffect, useState } from "react";
import toast from "react-hot-toast";
import BarLoading from "../../components/Loading/BarLoading";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import { Group, Image, NumberInput, Text, rem } from "@mantine/core";
import { config } from "../../config";
import { IconCheck, IconUpload, IconX } from "@tabler/icons-react";
import { Dropzone, IMAGE_MIME_TYPE } from "@mantine/dropzone";
import { IconPhoto } from "@tabler/icons-react";
import { Switch } from "@mantine/core";
import { yupResolver } from "@hookform/resolvers/yup";
import { ProductCreateValidations } from "./validations";
import SaveButton from "../../components/Buttons/SaveButton";
import CustomInput from "../../components/CustomInput/CustomInput";

const ProductCreate = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  let { id } = useParams();
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    getValues,
    control,

    formState: { errors },
  } = useForm({
    resolver: yupResolver(ProductCreateValidations),
    defaultValues: {
      is_active: true,
      category: "",
      main_category: null,
      brand: null,
      card_type: null,
      unit: null,
      weight: "",
      description: "",
    },
  });

  const weight = useWatch({
    control,
    name: "weight",
    defaultValue: null,
  });
  const photo = useWatch({
    control,
    name: "photo",
    defaultValue: "",
  });
  const isActive = useWatch({
    control,
    name: "is_active",
    defaultValue: true,
  });
  const price = useWatch({
    control,
    name: "price",
  });

  const mainCategory = useWatch({
    control,
    name: "main_category",
    defaultValue: "",
  });
  const slotHeight = useWatch({
    control,
    name: "slot_height",
  });
  const slotWidth = useWatch({
    control,
    name: "slot_width",
  });

  const addProduct = useMutation({
    mutationFn: (newProduct) => services.createProduct(newProduct),
  });
  const updateProduct = useMutation({
    mutationFn: (newProduct) => services.updateProduct(id, newProduct),
  });

  const {
    isLoading: categoryLoading,

    data: categoryData,
  } = useQuery({
    queryKey: ["categories"],
    queryFn: async () =>
      await services.productCategoryList(1, 100).then((res) => [
        ...res.data.data.map((item) => ({
          value: item._id,
          label: item.name,
        })),
      ]),
  });
  const { data: subCategoryData, isSuccess: subCategorySuccess } = useQuery({
    queryKey: ["subCategories", mainCategory],
    enabled: !!mainCategory,
    queryFn: async () =>
      await services
        .productSubCategoryListByCategoryId(mainCategory)
        .then((res) => [
          ...res.data.map((item) => ({
            value: item._id,
            label: item.name,
          })),
        ]),
  });
  const { data: brandData } = useQuery({
    queryKey: ["brands"],
    queryFn: async () =>
      await services.productBrandList(1, 100).then((res) => [
        ...res.data.data.map((item) => ({
          value: item._id,
          label: item.name,
        })),
      ]),
  });
  const { isLoading: cardTypeLoading, data: cardTypeData } = useQuery({
    queryKey: ["cardTypes"],
    queryFn: async () =>
      await services.productCardTypeList(1, 100).then((res) => [
        ...res.data.data.map((item) => ({
          value: item._id,
          label: item.name,
        })),
      ]),
  });

  const handleCancel = () => {
    reset();
    navigate("/product/list");
  };
  const onSubmit = async (data) => {
    let newProductPayload;
    let product;
    const formData = new FormData();

    try {
      newProductPayload = {
        ...data,
      };

      if (data.photo instanceof File && Object.values(errors).length === 0) {
        formData.append("type", data.photo?.type);
        formData.append("file", data.photo);
        const photo = await services.fileUpload(formData);
        delete newProductPayload.photo;
        newProductPayload = {
          ...newProductPayload,
          image: photo?.data.file,
        };
      }

      if (!id) {
        product = await addProduct.mutateAsync(newProductPayload);
      } else {
        product = await updateProduct.mutateAsync(newProductPayload);
      }

      if (!id && product) {
        toast.success(
          product?.data?.message
            ? product?.data?.message
            : t("notifications.success.product-create"),
          {
            duration: 3000,
          }
        );

        handleCancel();
      } else if (id && product) {
        toast.success(
          product?.data?.message
            ? product?.data?.message
            : t("notifications.success.product-update"),
          {
            duration: 3000,
          }
        );
        handleCancel();
      }
    } catch (e) {
      toast.error(e.response.data.message || t("notifications.error.message"), {
        duration: 3000,
      });
    }
  };

  const fetchProduct = useCallback(async () => {
    if (id) {
      setLoading(true);
      try {
        const product = await services.getProductById(id);
        Object.keys(product.data).forEach((key) => {
          if (key === "image") {
            setValue("photo", product.data[key]);
          }
          setValue(key, product.data[key]);
        });
      } catch (e) {
        navigate("/product");
        toast.error(t("notifications.error.product-notfound"), {
          duration: 3000,
        });
      } finally {
        setLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    reset();
    if (id) {
      fetchProduct();
    }
  }, [id]);
  return (
    <>
      {!categoryLoading && !cardTypeLoading ? (
        <div className="px-4 sm:px-6 lg:px-8">
          <form
            onSubmit={handleSubmit((data) =>
              onSubmit(data, getValues("photo"))
            )}
          >
            <div className="space-y-12">
              <div className="border-b border-white/10 pb-12">
                <div className="flex items-center justify-between">
                  <div>
                    <h2 className="text-base font-semibold leading-7 dark:text-white text-slate-700">
                      {id
                        ? t("titles.product-edit")
                        : t("titles.product-create")}
                    </h2>
                    <p className="mt-1 text-sm leading-6 text-gray-400">
                      {id
                        ? t("descriptions.product-edit")
                        : t("descriptions.product-create")}
                    </p>
                  </div>
                  <div className="sm:col-span-full">
                    <label
                      htmlFor="first-name"
                      className="block text-xs font-medium leading-6 dark:text-white text-slate-700"
                    >
                      {t("labels.is-active")}
                    </label>
                    <div className="mt-2">
                      <Switch
                        checked={isActive}
                        onChange={(event) =>
                          setValue("is_active", event.target.checked)
                        }
                        color="teal"
                        size="md"
                        thumbIcon={
                          isActive ? (
                            <IconCheck
                              style={{ width: rem(12), height: rem(12) }}
                              color="green"
                              stroke={3}
                            />
                          ) : (
                            <IconX
                              style={{ width: rem(12), height: rem(12) }}
                              color="red"
                              stroke={3}
                            />
                          )
                        }
                      />
                    </div>
                  </div>
                </div>

                <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 max-w-full">
                  <div className="sm:col-span-full">
                    <CustomInput
                      label={t("product.name")}
                      type="text"
                      name="name"
                      register={register}
                      errors={errors}
                      placeholder={t("product.name")}
                    />
                  </div>
                  <div className="sm:col-span-3">
                    <label
                      htmlFor="first-name"
                      className="block text-sm font-medium leading-6 dark:text-white text-slate-700"
                    >
                      {t("product.price")}
                    </label>
                    <div className="mt-2">
                      <NumberInput
                        {...register("price")}
                        name="price"
                        hideControls
                        placeholder={t("product.price")}
                        onChange={(event) => {
                          setValue("price", event);
                        }}
                        value={price}
                        maxLength={10}
                        classNames={{
                          input: `dark:!bg-gray-800 !bg-gray-300/100   !ring-1 !border-none !rounded-md ring-inset ring-white/5 dark:!text-white placeholder:!text-gray-500 !text-slate-700 focus:!ring-2 focus:!ring-inset focus:!ring-orange-500 !text-base md:!text-sm sm:leading-6 ${
                            errors.price?.message
                              ? "!ring-red-500 dark:!ring-red-500"
                              : ""
                          }}`,
                          wrapper: "!bg-gray-800 rounded-md !text-white",
                          dropdown:
                            "!bg-white/5 !bg-gray-300/100   !border-none !rounded-md ring-inset ring-white/5 dark:!text-white !text-slate-700 focus:!ring-2 focus:!ring-inset focus:!ring-orange-500 !text-base md:!text-sm sm:leading-6",
                          option: "hover:bg-gray-700",
                        }}
                      />
                      <p className="mt-2 text-sm text-red-600" id="email-error">
                        {t(errors.price?.message)}
                      </p>
                    </div>
                  </div>
                  <div className="sm:col-span-3">
                    <CustomInput
                      label={t("product.barcode")}
                      type="text"
                      name="barcode"
                      register={register}
                      errors={errors}
                      placeholder={t("product.barcode")}
                    />
                  </div>
                  <div className="sm:col-span-3">
                    <span className="block text-sm font-medium leading-6 dark:text-white text-slate-700">
                      {t("product.main-category")}
                    </span>
                    <div className="mt-2">
                      <CustomSelect
                        control={control}
                        placeholder={t("placeholders.select")}
                        name="main_category"
                        data={categoryData}
                        error={errors?.main_category?.message ? true : false}
                        searchable
                        clearable
                        height={"20px"}
                      />
                      <p className="mt-2 text-sm text-red-600" id="email-error">
                        {t(errors.main_category?.message)}
                      </p>
                    </div>
                  </div>
                  {subCategorySuccess && (
                    <div className="sm:col-span-3">
                      <div>
                        <span className="block text-sm font-medium leading-6 dark:text-white text-slate-700">
                          {t("product.category")}
                        </span>
                        <div className="mt-2">
                          <CustomSelect
                            control={control}
                            placeholder={t("placeholders.select")}
                            name="category"
                            data={subCategoryData}
                            searchable
                            clearable
                            error={errors?.category?.message ? true : false}
                            height={"20px"}
                          />

                          <p
                            className="mt-2 text-sm text-red-600"
                            id="email-error"
                          >
                            {t(errors.category?.message)}
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="sm:col-span-3">
                    <label className="block text-sm font-medium leading-6 dark:text-white text-slate-700">
                      {t("product.brand")}
                    </label>
                    <div className="mt-2">
                      <CustomSelect
                        control={control}
                        placeholder={t("placeholders.select")}
                        name="brand"
                        data={brandData}
                        searchable
                        error={errors?.brand?.message ? true : false}
                        height={"20px"}
                      />
                      <p className="mt-2 text-sm text-red-600" id="email-error">
                        {t(errors.brand?.message)}
                      </p>
                    </div>
                  </div>
                  <div className="sm:col-span-3">
                    <label className="block text-sm font-medium leading-6 dark:text-white text-slate-700">
                      {t("product.card-type")}
                    </label>
                    <div className="mt-2">
                      <CustomSelect
                        control={control}
                        placeholder={t("placeholders.select")}
                        name="card_type"
                        data={cardTypeData}
                        searchable
                        error={errors?.card_type?.message ? true : false}
                        height={"20px"}
                      />
                      <p className="mt-2 text-sm text-red-600" id="email-error">
                        {t(errors.card_type?.message)}
                      </p>
                    </div>
                  </div>
                  <div className="sm:col-span-3">
                    <label className="block text-sm font-medium leading-6 dark:text-white text-slate-700">
                      {t("product.slot-height")}
                    </label>
                    <div className="mt-2">
                      <NumberInput
                        value={slotHeight}
                        disabled={id ? true : false}
                        max={Number(slotWidth) > 1 ? 1 : 3}
                        placeholder={t("product.slot-height")}
                        maxLength={1}
                        onChange={(event) => {
                          setValue(
                            "slot_height",
                            Number(slotWidth) > 1 ? 1 : event
                          );
                          setValue(
                            "slot_width",
                            Number(slotHeight) > 1 ? 1 : slotWidth
                          );
                        }}
                        hideControls
                        classNames={{
                          input: `dark:!bg-gray-800  !bg-gray-300/100 dark:!text-white !text-slate-700 !ring-1 !border-none !rounded-md ring-inset dark:!placeholder-white/30 !placeholder-slate-700 ring-white/5 ! focus:!ring-2 focus:!ring-inset focus:!ring-orange-500 !text-base md:!text-sm sm:leading-6 !w-full
                          ${
                            errors.slot_height?.message &&
                            "!ring-red-500 dark:!ring-red-500"
                          }
                          `,
                          wrapper:
                            "dark:!bg-gray-800 !bg-gray-300/100 rounded-md dark:!text-white !text-slate-700",
                          dropdown:
                            "dark:!bg-gray-800 !bg-gray-300/100 dark:!text-white !text-slate-700 !border-none !rounded-md ring-inset ring-white/5 focus:!ring-2 focus:!ring-inset focus:!ring-orange-500 !text-base md:!text-sm sm:leading-6",
                          option: "hover:dark:bg-gray-700 hover:bg-gray-200/50",
                        }}
                      />
                      <p className="mt-2 text-sm text-red-600" id="email-error">
                        {t(errors.slot_height?.message)}
                      </p>
                    </div>
                  </div>
                  <div className="sm:col-span-3">
                    <label className="block text-sm font-medium leading-6 dark:text-white text-slate-700">
                      {t("product.slot-width")}
                    </label>
                    <div className="mt-2">
                      <NumberInput
                        disabled={id ? true : false}
                        value={Number(slotWidth)}
                        max={Number(slotHeight) > 1 ? 1 : 3}
                        maxLength={1}
                        placeholder={t("product.slot-width")}
                        onChange={(event) => {
                          setValue(
                            "slot_width",
                            Number(slotHeight) > 1 ? 1 : Number(event)
                          );
                          setValue(
                            "slot_height",
                            Number(slotWidth) > 1 ? 1 : Number(slotHeight)
                          );
                        }}
                        hideControls
                        classNames={{
                          input: `dark:!bg-gray-800  !bg-gray-300/100 dark:!text-white !text-slate-700 !ring-1 !border-none !rounded-md ring-inset dark:!placeholder-white/30 !placeholder-slate-700 ring-white/5 ! focus:!ring-2 focus:!ring-inset focus:!ring-orange-500 !text-base md:!text-sm sm:leading-6 !w-full
                          ${
                            errors.slot_width?.message &&
                            "!ring-red-500 dark:!ring-red-500"
                          }
                          `,
                          wrapper:
                            "dark:!bg-gray-800 !bg-gray-300/100 rounded-md dark:!text-white !text-slate-700",
                          dropdown:
                            "dark:!bg-gray-800 !bg-gray-300/100 dark:!text-white !text-slate-700 !border-none !rounded-md ring-inset ring-white/5 focus:!ring-2 focus:!ring-inset focus:!ring-orange-500 !text-base md:!text-sm sm:leading-6",
                          option: "hover:dark:bg-gray-700 hover:bg-gray-200/50",
                        }}
                      />
                      <p className="mt-2 text-sm text-red-600" id="email-error">
                        {t(errors.slot_width?.message)}
                      </p>
                    </div>
                  </div>
                  <div className="sm:col-span-3">
                    <label className="block text-sm font-medium leading-6 dark:text-white text-slate-700">
                      {t("product.unit")}
                    </label>
                    <div className="mt-2">
                      <CustomSelect
                        control={control}
                        placeholder={t("placeholders.select")}
                        name="unit"
                        data={["kg", "g", "l", "ml"]}
                        error={errors?.unit?.message ? true : false}
                        searchable
                        height={"20px"}
                      />
                      <p className="mt-2 text-sm text-red-600" id="email-error">
                        {t(errors.unit?.message)}
                      </p>
                    </div>
                  </div>
                  <div className="sm:col-span-3">
                    <label className="block text-sm font-medium leading-6 dark:text-white text-slate-700">
                      {t("product.weight")}
                    </label>
                    <div className="mt-2">
                      <NumberInput
                        value={weight}
                        placeholder={t("product.weight")}
                        onChange={(event) => {
                          setValue("weight", event);
                        }}
                        hideControls
                        classNames={{
                          input: `dark:!bg-gray-800  !bg-gray-300/100 dark:!text-white !text-slate-700 !ring-1 !border-none !rounded-md ring-inset dark:!placeholder-white/30 !placeholder-slate-700 ring-white/5 ! focus:!ring-2 focus:!ring-inset focus:!ring-orange-500 !text-base md:!text-sm sm:leading-6 !w-full
                          ${
                            errors.weight?.message &&
                            "!ring-red-500 dark:!ring-red-500"
                          }
                          `,
                          wrapper:
                            "dark:!bg-gray-800 !bg-gray-300/100 rounded-md dark:!text-white !text-slate-700",
                          dropdown:
                            "dark:!bg-gray-800 !bg-gray-300/100 dark:!text-white !text-slate-700 !border-none !rounded-md ring-inset ring-white/5 focus:!ring-2 focus:!ring-inset focus:!ring-orange-500 !text-base md:!text-sm sm:leading-6",
                          option: "hover:dark:bg-gray-700 hover:bg-gray-200/50",
                        }}
                      />
                      <p className="mt-2 text-sm text-red-600" id="email-error">
                        {t(errors.weight?.message)}
                      </p>
                    </div>
                  </div>

                  <div className="sm:col-span-3">
                    <label
                      htmlFor="description"
                      className="block text-sm font-medium leading-6 dark:text-white text-slate-700"
                    >
                      {t("product.description")}
                    </label>
                    <div className="mt-2">
                      <textarea
                        {...register("description")}
                        type="text"
                        autoComplete="description"
                        placeholder={t("product.description")}
                        className={`block w-full rounded-md border-0 dark:bg-gray-800 bg-gray-300/70 py-1.5 dark:text-white text-slate-700 shadow-sm ring-1 ring-inset dark:ring-white/10 ring-gray-400/20 focus:ring-2 focus:ring-inset focus:ring-orange-500 !text-base md:!text-sm sm:leading-6
                        ${
                          errors.description?.message &&
                          "!ring-red-500 dark:!ring-red-500"
                        }
                        `}
                      />
                      <p className="mt-2 text-sm text-red-600" id="email-error">
                        {t(errors.description?.message)}
                      </p>
                    </div>
                  </div>
                  <div className="sm:col-span-3">
                    <label
                      htmlFor="phone-number"
                      className="block text-sm font-medium leading-6 dark:text-white text-slate-700"
                    >
                      Fotoğraf
                    </label>
                    <Dropzone
                      onDrop={(files) => {
                        setValue("photo", files[0]);
                      }}
                      onReject={(files) => console.log("rejected files", files)}
                      maxSize={3 * 1024 ** 2}
                      multiple={false}
                      classNames={{
                        root: "dark:!bg-gray-800 !bg-gray-300/70 dark:!text-white !text-slate-700 mt-2",
                      }}
                      accept={IMAGE_MIME_TYPE}
                    >
                      <Group
                        justify="center"
                        gap="xl"
                        mih={100}
                        style={{ pointerEvents: "none" }}
                      >
                        <Dropzone.Accept>
                          <IconPhoto
                            style={{
                              width: rem(52),
                              height: rem(52),
                              color: "var(--mantine-color-green-6)",
                            }}
                            stroke={1.5}
                          />
                        </Dropzone.Accept>
                        <Dropzone.Reject>
                          <IconX
                            style={{
                              width: rem(52),
                              height: rem(52),
                              color: "var(--mantine-color-red-6)",
                            }}
                            stroke={1.5}
                          />
                        </Dropzone.Reject>
                        <Dropzone.Idle>
                          {photo ? (
                            <div className="w-full h-full flex justify-center items-center">
                              <Image
                                radius="md"
                                h={"auto"}
                                className="max-h-[200px] w-full"
                                w="100%"
                                fit="cover"
                                src={
                                  photo instanceof File
                                    ? URL.createObjectURL(photo)
                                    : `${config.CDN_URL}${photo}`
                                }
                              />
                            </div>
                          ) : (
                            <div className="flex flex-col items-center justify-center">
                              <IconUpload
                                style={{
                                  width: rem(52),
                                  height: rem(52),
                                  color: "var(--mantine-color-gray-6)",
                                }}
                                stroke={1.5}
                              />
                              <Text
                                className="text-white"
                                style={{ fontSize: rem(12) }}
                              >
                                {t("placeholders.upload")}
                              </Text>
                            </div>
                          )}
                        </Dropzone.Idle>
                      </Group>
                    </Dropzone>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-6 flex items-center justify-end gap-x-6">
              <button
                type="button"
                onClick={handleCancel}
                className="text-sm font-semibold leading-6 dark:text-white text-slate-700"
              >
                {t("actions.cancel")}
              </button>
              <SaveButton
                disabled={id ? updateProduct.isLoading : addProduct.isLoading}
              />
            </div>
          </form>
        </div>
      ) : (
        <BarLoading />
      )}
    </>
  );
};

export default ProductCreate;
