import { DateInput } from "@mantine/dates";
import { useTranslation } from "react-i18next";
const CustomDateInput = (props) => {
  const { t } = useTranslation();
  return (
    <>
      <DateInput
        value={props.value}
        popoverProps={{
          classNames: {
            dropdown:
              "dark:!bg-gray-800 !bg-gray-300/100 !border-none dark:!text-white !text-slate-700",
          },
        }}
        classNames={{
          weekdaysRow:
            "dark:!bg-gray-800 !bg-gray-300/100  !border-none dark:!text-white !text-slate-700",
          yearsList:
            "dark:!bg-gray-800 !bg-gray-300/100  !border-none dark:!text-white !text-slate-700",
          yearsListCell:
            "dark:!bg-gray-800 !bg-gray-300/100  !border-none dark:!text-white !text-slate-700",
          yearsListRow:
            "dark:!bg-gray-800 !bg-gray-300/100  !border-none dark:!text-white !text-slate-700",
          monthCell:
            "dark:!bg-gray-800 !bg-gray-300/100  !border-none dark:!text-white !text-slate-700",
          monthsListCell:
            "dark:!bg-gray-800 !bg-gray-300/70  !border-none dark:!text-white !text-slate-700",
          monthsList:
            "dark:!bg-gray-800 !bg-gray-300/100  !border-none dark:!text-white !text-slate-700",
          monthTbody:
            "dark:!bg-gray-800 !bg-gray-300/70  !border-none dark:!text-white !text-slate-700",
          monthRow:
            "dark:!bg-gray-800 !bg-gray-300/100  !border-none dark:!text-white !text-slate-700",
          monthsListRow:
            "dark:!bg-gray-800 !bg-gray-300/100  !border-none dark:!text-white !text-slate-700",
          section:
            "dark:!bg-gray-800 !bg-gray-300/100  !border-none dark:!text-white !text-slate-700",

          input: `w-full dark:!bg-gray-800 !bg-gray-300/100  dark:!text-white !text-slate-700 !ring-1 !border-none mt-2 !rounded-md ring-inset ring-white/5 focus:!ring-2 focus:!ring-inset focus:!ring-orange-500 !text-base md:!text-sm sm:leading-6 ${
            props.errors[props.keyParam]?.message
              ? "!ring-red-500 dark:!ring-red-500"
              : ""
          } `,
          wrapper:
            "w-full dark:!bg-gray-800 !bg-gray-300/100  rounded-md dark:!text-white !text-slate-700 ",
          root: "w-full ",
          label:
            "dark:!text-white !text-slate-700 !text-sm !font-medium !leading-6",
          calendarHeaderControlIcon:
            "dark:!bg-gray-800 !bg-gray-300/100  !border-none",
          day: "dark:!bg-gray-800 dark:hover:!bg-gray-700 hover:!bg-gray-100   !bg-gray-300/100  !border-none dark:!text-white !text-slate-700  hover:!bg-gray-700",
          description:
            "dark:!bg-gray-800 !bg-gray-300/100  !border-none dark:!text-white !text-slate-700",
          month:
            "dark:!bg-gray-800 !bg-gray-300/100  !border-nonedark:!text-white !text-slate-700 ",

          levelsGroup: "dark:!bg-gray-800 !bg-gray-300/100  !ring-none",
          yearsListControl:
            "dark:!bg-gray-800 !bg-gray-300/100 dark:!text-white !text-slate-700 ",
          monthsListControl:
            "dark:!bg-gray-800 !bg-gray-300/100  dark:!text-white !text-slate-700 ",
          weekday:
            "dark:!bg-gray-800 !bg-gray-300/100  dark:!text-white !text-slate-700 ",
          monthThead:
            "dark:!bg-gray-800 !bg-gray-300/100  dark:!text-white !text-slate-700 ",
          calendarHeaderLevel: "dark:!bg-gray-800 !bg-gray-300/100 ",
          calendarHeader:
            "dark:!bg-gray-800 !bg-gray-300/100  dark:!text-white !text-slate-700  !border-none",
          calendarHeaderControl:
            "dark:hover:!bg-gray-800 hover:!bg-gray-400/70 dark:!bg-gray-800 !bg-gray-300/100 ",
        }}
        {...props}
      />
      <p className="mt-2 ml-1 text-sm text-red-600">
        {t(props.errors[props.keyParam]?.message) ||
          props.errors[props.keyParam]?.message}
      </p>
    </>
  );
};
export default CustomDateInput;
