import * as yup from "yup";
const validations = yup.object().shape({
  name: yup.string().required("validationMessages.required"),
  style: yup.string().required("validationMessages.required"),
  description: yup.string().required("validationMessages.required"),
  end_date: yup.string().required("validationMessages.required"),
  end_type: yup.string().required("validationMessages.required"),
  end_count: yup.number().required("validationMessages.required"),
  products: yup.array().required("validationMessages.required"),
  type_ids: yup.array().min(1, "validationMessages.required"),
  type: yup.string().required("validationMessages.required"),
  photo: yup.string().required("validationMessages.required"),
  price: yup.string().required("validationMessages.required"),
});

export default validations;
