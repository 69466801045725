import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import services from "../../services";
import { Badge, Pagination } from "@mantine/core";
import { useEffect, useLayoutEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import toast from "react-hot-toast";
import ConfirmModal from "../../components/ConfirmModal/ConfirmModal";
import TableSkeleton from "../../components/Skeleton/TableSkeleton";
import Layout from "../../components/Layout/Layout";
import dayjs from "dayjs";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import { amountFormatter } from "../../utils/helper";

const Transactions = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [id, setId] = useState(null);
  const [refundModalVisible, setRefundModalVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(
    Number(searchParams.get("page")) ? Number(searchParams.get("page")) : 1
  );
  const [pageSize, setPageSize] = useState(
    searchParams.get("pageSize") ? searchParams.get("pageSize") : 10
  );
  const [search, setSearch] = useState(
    searchParams.get("search") ? searchParams.get("search") : ""
  );

  const {
    isLoading,
    error,
    data,
    isFetching,
    refetch: refetchBrand,
  } = useQuery({
    queryKey: ["productBrands", currentPage, pageSize, search],
    queryFn: async () =>
      await services
        .getTransactions(currentPage, pageSize, {})
        .then((res) => res.data),
  });

  console.log(data)
  useLayoutEffect(() => {
    const search = searchParams.get("search");
    if (search) {
      setSearchParams({ page: 1, pageSize: pageSize, search: search });
    } else {
      // setSearchParams({ page: , pageSize: pageSize });
    }
  }, [currentPage, pageSize, setSearchParams, search, searchParams]);

  useEffect(() => {
    if (data?.data?.length === 0 && currentPage > 1) {
      setSearchParams({ page: 1, pageSize: pageSize });
    }
  }, [data, currentPage, pageSize, setSearchParams]);
  if (error) return <p>Error :(</p>;

  const handleNavigate = (page, id) => {
    console.log(page, id);
    switch (page) {
      case "refund":
        setId(id);
        setRefundModalVisible(true);

        break;
      case "detail":
        navigate(`/transactions/detail/${id}`);
        break;
    }
  };

  const handlePageChange = (page) => {
    const search = searchParams.get("search");
    if (data.data.length === 0 && page > 1) {
      setCurrentPage(page - 1);
    }

    setCurrentPage(page);
    if (search) {
      setSearchParams({ page: page, pageSize: pageSize, search: search });
      return;
    } else {
      setSearchParams({ page: page, pageSize: pageSize });
      return;
    }
  };
  const handleRefund = async (id) => {
    await services.productBrandrefund(id).then((res) => {
      if (res.success) {
        toast.success(
          res?.message
            ? res.message
            : t("notifications.success.product-brand-refund")
        );

        setRefundModalVisible(false);
        refetchBrand();
      } else {
        toast.error(
          res?.message ? res.message : t("notifications.error.message")
        );
      }
    });
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <ConfirmModal
        isOpen={refundModalVisible}
        onConfirm={() => handleRefund(id)}
        onClose={() => {
          setRefundModalVisible(false);
          setId(null);
        }}
        title={t("titles.refund-order")}
        confirmText={t("descriptions.refund-order")}
      />
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 dark:text-white text-slate-700">
            {t("navigationItems.transactions")}
          </h1>
          <p className="mt-2 text-sm dark:text-gray-400 text-gray-500">
            {t("descriptions.transaction-list")}
          </p>
        </div>

        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none"></div>
      </div>
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="dark:bg-gray-800 bg-gray-300 text-slate-700">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold dark:text-gray-300 text-slate-700 sm:pl-6"
                    >
                      {t("user.user")}
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold dark:text-gray-300 text-slate-700 sm:pl-6"
                    >
                      {t("labels.transaction-status")}
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold dark:text-gray-300 text-slate-700 sm:pl-6"
                    >
                      {t("labels.made-transaction-type")}
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold dark:text-gray-300 text-slate-700 sm:pl-6"
                    >
                      {t("user.action-date")}
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold dark:text-gray-300 text-slate-700 sm:pl-6"
                    >
                      {t("labels.amount-mny")}
                    </th>
                    <th
                      scope="col"
                      className="flex py-3.5 pl-3 pr-4 sm:pr-6 w-full "
                    ></th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 dark:bg-gray-800 bg-gray-200 text-slate-700">
                  {isLoading || isFetching ? (
                    <TableSkeleton colSize={6} />
                  ) : (
                    data.data.map((transaction, index) => (
                      <tr
                        key={`${transaction.key}-${index.toString()}`}
                        className={`${
                          index % 2 === 0
                            ? "dark:bg-slate-700/80 bg-gray-200"
                            : "dark:bg-slate-600/80 bg-gray-100"
                        } hover:bg-orange-200/90 dark:hover:bg-orange-600/20`}
                      >
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium dark:text-gray-300 text-slate-700 sm:pl-6">
                          {(transaction?.user?.name || "İsimsiz") + ' ' + transaction?.user?.last_name}
                        </td>

                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium dark:text-gray-300 text-slate-700 sm:pl-6">
                          <Badge
                            color={transaction.is_success ? transaction.cart.length === transaction.cart.filter((a) => a.status !== 'REFUNDED' && a.status !== 'NEED_TO_REFUND').length ? 'green' : "yellow" : transaction.cart?.reduce((a,b) => a+=b.price,0) === 0 ? 'blue' : !transaction.is_success && transaction?.error_code ? "red" : transaction.device_id?.location_description ? 'red' : 'purple'}
                          >
                            {transaction.is_success
                              ? t("status.success") + ` ${transaction.cart.length} Üründe ${transaction.cart.filter((a) => a.status !== 'REFUNDED' && a.status !== 'NEED_TO_REFUND').length}` : transaction.cart?.reduce((a,b) => a+=b.price,0) === 0 ? 'Ücretsiz' : !transaction.is_success && transaction?.error_code ? t("status.failed") : transaction.device_id?.location_description ? t("status.failed") : "QR Okutulmadı"}
                          </Badge>
                        </td>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium dark:text-gray-300 text-slate-700 sm:pl-6">
                          {transaction?.error_code ? transaction.error_code : transaction.device_id ? transaction.device_id?.location_description : transaction.cart.reduce((a,b) => a+=b.price,0) === 0 ? 'Ücretsiz Ürün Tanımlandı' : !transaction.is_success ? 'QR Oluşturuldu Kullanılmadı' : "QR Oluşturuldu"}
                        </td>

                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium dark:text-gray-300 text-slate-700 sm:pl-6">
                          {dayjs(transaction.created_at).format(
                            "DD-MM-YYYY HH:mm"
                          )}
                        </td>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium dark:text-gray-300 text-slate-700 sm:pl-6">
                          {amountFormatter(transaction.cart.reduce((a,b) => a+=b.price,0)) +
                            " " +
                            (transaction?.cart?.unit || "TL")}
                        </td>
                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4  text-right text-sm font-medium w-[150px] sm:pr-6 ">
                          <button
                            onClick={() =>
                              handleNavigate("detail", transaction._id)
                            }
                            className="text-orange-600 hover:text-orange-900 ml-3"
                          >
                            {t("actions.detail")}
                            <span className="sr-only">, {transaction._id}</span>
                          </button>
                          {transaction?.is_success && (
                            <button
                              onClick={() =>
                                handleNavigate("refund", transaction._id)
                              }
                              className="text-orange-600 hover:text-orange-900 ml-3"
                            >
                              {t("actions.refund")}
                              <span className="sr-only">
                                , {transaction.user.name}
                              </span>
                            </button>
                          )}
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-5  overflow-x-auto w-full  sm:gap-1  sm:flex-row items-center  my-5">
          <div className="flex justify-center w-full items-center">
            <Pagination
              total={data?.totalPageCount}
              siblings={1}
              size={"md"}
              defaultValue={
                Number(searchParams.get("page"))
                  ? Number(searchParams.get("page"))
                  : 1
              }
              value={currentPage}
              onChange={handlePageChange}
              color="orange"
              classNames={{
                control:
                  "flex items-center justify-center gap-x-2 dark:disabled:!bg-gray-700/50  dark:[&[data-active=true]]:!bg-orange-700/50 dark:[&[data-active=true]]:!border-orange-700  dark:[&[data-active=true]]:!border-orange-700 [&[data-active=true]]:!bg-orange-600 dark:bg-orange-800/90 [&[data-active=true]]:!border-orange-300/90 !bg-gray-200/90 !border   dark:!bg-slate-800 dark:!text-white  :disabled:!bg-gray-100/50 !rounded-lg ",
              }}
            />
          </div>
          <div className=" flex justify-end w-[80px]">
            <CustomSelect
              withoutController={true}
              placeholder={t("placeholders.select")}
              onChange={(value) => {
                setPageSize(value);
                setSearchParams({ page: 1, pageSize: value || 10 });
                setCurrentPage(1);
              }}
              data={[5, 10, 20, 30, 50]}
              value={pageSize}
              name="pageSize"
              searchable={false}
              height={"20px"}
              width={20}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Transactions;
